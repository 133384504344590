import React from 'react'
import { Typography } from "@mui/material";
import '../Css/About.css'
import compliance from '../images/compliance.png'

const AboutMain = () => {
    return(
        <div className='about-main'>
            <div className='div1-l'>
                <div className='div1-l-text'>
                    <Typography variant='h2' sx={{marginBottom: '2vh', border: '0px solid blue'}}>
                        Our Mission
                    </Typography>
                    <Typography variant='body1'>
                        Laver is committed to revolutionizing manufacturing compliance with advanced AI software. Our mission is to streamline processes, ensuring adherence to regulations while enchancing efficiency and excellence in operations. 
                    </Typography>
                </div>
            </div>
            <div className='div1-r'>
                <div className="div1-r-img">
                    <img src={compliance} alt="Error" style={{width:'35vw', height:'auto', objectFit: 'contain'}}/>
                </div>
            </div>
        </div>
    )
}
export default AboutMain;