//import './App.css';
import React from "react";
import { createBrowserRouter, RouterProvider, Route, createRoutesFromElements } from "react-router-dom";
import About from "./Pages/About.jsx";
import Products from "./Pages/Products.jsx";
import Home from "./Pages/Home.jsx"

const App = () => {

  const router = createBrowserRouter(
    createRoutesFromElements([
      <Route path="/" element={<Home/>}></Route>,
      <Route path="/products" element={<Products/>}></Route>,
      <Route path="/about" element={<About />}></Route>,
    ])
  )

  return (
    <div>
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;
