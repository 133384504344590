import React from 'react'
import NavBar from '../Components/NavBar';
import AboutMain from '../Components/AboutMain';
import FooterComponent from '../Components/FooterComponent';

const About = () => {
    return(
        <div className='about'>
            <NavBar></NavBar>
            <AboutMain></AboutMain>
            <FooterComponent></FooterComponent>
        </div>
    )
}
export default About;