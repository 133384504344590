import { Link } from "react-router-dom";
import { AppBar, Typography, Toolbar, Button, Grid } from "@mui/material";
import exampleImage from '../images/Laver-Logo-White.png';
import Theme from "../Theme.jsx";


const NavBar = () => {
  const handleClick = () => {
    window.open('mailto:jeongwook.lyun@gmail.com');
  }

  return (
    <AppBar position="static" color="transparent" elevation={0} sx={{}}>
      <Toolbar>
        <Grid container alignItems="center">
            <Grid item xs={4}>
                <Button component={Link} to="/" sx={{flexGrow: 1, marginLeft:"10vw", textAlign: "left"}}>
                    <img src={exampleImage} alt="button image" style={{width:"200px", height: "auto"}}/>
                </Button>
            </Grid>
            <Grid item xs={8} container justifyContent="flex-end">
                <Button component={Link} to="/products" style={{marginRight: "1.75vw", color: Theme.palette.primary.contrastText }} >
                  <Typography variant='navbar' sx={{textTransform: 'capitalize'}}> 
                    Products
                  </Typography>
                </Button>
                <Button component={Link} to="/about" style={{marginRight: "1.75vw", color: Theme.palette.primary.contrastText }}>
                  <Typography variant='navbar' sx={{textTransform: 'capitalize'}}> 
                    About
                  </Typography>
                </Button>
                <Button onClick={handleClick} sx={{marginRight: "12.5vw", color: Theme.palette.primary.contrastText}}>
                  <Typography variant='navbar' sx={{textTransform: 'capitalize'}}> 
                    Contact
                  </Typography>
                </Button>
            </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;

