import React from 'react'
import '../Css/FooterComponent.css'
import { Link } from "react-router-dom";
import exampleImage from '../images/Laver-Logo-Black.png'
import { Typography, Grid, TextField, Button} from '@mui/material';


const FooterComponent = () => {
    
    const handleContact = () => {
        window.open('mailto:jeongwook.lyun@gmail.com');
      }    
    const handleLinkedIn = () => {
        window.open('https://www.linkedin.com/in/jeongwook-yun/');
    }  
    // loads pages at top 
    const handleClick = () => {

        window.scrollTo(0, 0); // Scroll to the top
    };
    
    return (
        <footer className="footer">
            <Grid container className='div1'>
                <Grid item  container xs ={12} className="div2">
                    <Grid item xs={3}>
                        <Link to="/" onClick={handleClick}><img src={exampleImage} alt="button image" style={{width:"200px", height: "auto", objectFit:'contain'}}/></Link>
                    </Grid>
                    <Grid item xs={2}>
                        <ul className="div2-ul">
                            <Link to="/" className="div2-li" onClick={handleClick}><li>Home</li></Link>
                            <Link to="/products" className="div2-li" onClick={handleClick}><li>Products</li></Link>
                            <Link to="/about" className="div2-li" onClick={handleClick}><li>About</li></Link>
                            <li className="div2-li" onClick={handleContact}>Contact</li>
                        </ul>
                    </Grid>
                    <Grid item xs={2}>
                        <ul className="div3-ul">
                            <Link to="/products" className="div2-li" onClick={handleClick}><li>Products</li></Link>
                            <Link to="/products" className="div2-li" onClick={handleClick}><li>GoodDoc</li></Link>
                        </ul>
                    </Grid>
                    <Grid item xs={2}>
                        <ul className="div2-ul">
                            <li className="div2-li" onClick={handleContact}>Contact</li>
                            <li className="div2-li" onClick={handleLinkedIn}>LinkedIn</li>
                        </ul>
                    </Grid>
                    
                    <Grid item  container xs={3} lg={3} sx={{ display: 'flex', justifyContent: 'center', marginTop:'1vh'}} spacing={4} >
                        <Grid item xs = {12}>
                        <Typography variant='h3' sx={{color:'black', display:'flex', justifyContent:'center', textAlign:'center'}}> Join Wait List</Typography>
                        </Grid>
                        <Grid item xs={12} container sx={{display:'flex', justifyContent:'center'}}>
                            <TextField
                                defaultValue=""
                                placeholder="Email"
                                size="small"
                                variant="outlined"
                                InputProps={{ notched: true }}
                                sx={{width:'10vw', borderRadius: '.5rem'}}
                            />
                        </Grid>
                        <Grid item xs={12} container sx={{display:'flex', justifyContent:'center'}}>
                            <Button variant='contained' sx={{color:'white', height: '2rem', width:'5rem', fontSize: '1rem', fontWeight: 600, borderRadius: '2rem'}}>
                                Join
                            </Button>
                        </Grid>
                    </Grid>
                    
                </Grid>
                <hr className="div2-hr"/>
                <Grid item xs={12} className="div4">
                    <p>© Laver 2024</p>
                </Grid>
            </Grid>
                
        </footer>
    );
  };
  
  export default FooterComponent;
