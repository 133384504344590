import React from 'react';
import '../Css/Carousel.css';

const Carousel = ({ imageSources }) => {
  const logoSlides = imageSources.map((src, index) => (
    <img key={index} src={src} alt={`logo-${index}`} />
  ));

  return (
    <div className="logos">
        {/* duplicate to make animation seamless */}
      <div className="logos-slide">{logoSlides}</div>
      <div className="logos-slide">{logoSlides}</div>
    </div>
  );
};

export default Carousel;