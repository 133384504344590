import React from 'react'
import '../Css/Products.css'
import { Grid, Typography } from '@mui/material';
import FDALogo from '../images/FDALogo.png';
import FDACitation483 from '../images/FDA-Citation-483.jpg';
import errorIdentification from '../images/errorIdentification.png';
import rootCauseAnalysis from '../images/rootCauseAnalysis.png';
import CAPA from '../images/CAPA.png';
import GoodDoc from '../images/GoodDoc.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassChart, faFlag, faCheck } from '@fortawesome/free-solid-svg-icons';

const ProductsMain = () => {
    return(
        <div className='products-main'>
            
            <Grid container sx={{padding: '5vh 12.5vw'}}>
                <Grid item xs={12} md={6} lg={6} sx={{paddingRight:'2vw'}}>
                    <Typography variant='h5'>Products</Typography>
                    <Typography variant='h2'sx={{margin: '2vh 0'}}>GoodDoc</Typography>
                    <Typography variant='body2' sx={{marginRight:'5vw'}}>AI-Powered Compliance checking software for Good Documentation Practices on the documents in the manufacturing and development process. </Typography>
                    <Grid item container alignItems={'center'}>
                        <Grid item xs={1}>
                            <div className='vertical-line'></div>
                        </Grid>
                        <Grid item xs={11} sx={{justifyContent: 'center'}}>
                            <Grid item container alignItems={'center'} sx={{marginBottom: '13vh'}}>
                                <Grid item alignItems="center" xs={3} >
                                    <div className='icon-background'>
                                        <img src={errorIdentification} alt='error identification' style={{width:'7vh', height:'auto', objectFit: 'contain' }} />
                                    </div>
                                </Grid>
                                <Grid item xs={9} alignItems="center" >
                                    <Typography variant='body1'>Document Error Identification</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container alignItems={'center'} sx={{marginBottom: '13vh'}}>
                                <Grid item alignItems="center" xs={3} >
                                    <div className='icon-background'>
                                        <img src={CAPA} alt='Corrective and PReventice Action' style={{width:'7vh', height:'auto', objectFit: 'contain' }} />
                                    </div>
                                </Grid>
                                <Grid item xs={9} alignItems="center" >
                                    <Typography variant='body1'>Corrective and Preventive Action (CAPA)</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container alignItems={'center'}>
                                <Grid item alignItems="center" xs={3}>
                                    <div className='icon-background' sx={{paddingLeft:'2vh'}}>
                                        <img src={rootCauseAnalysis} alt='Root Cause Analysis' style={{width:'6vh', height:'auto', objectFit: 'contain' }} />
                                    </div>
                                </Grid>
                                <Grid item xs={9} alignItems="center">
                                    <Typography variant='body1'>Root Cause Analysis</Typography>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={6} sx={{display:'flex', justifyContent:'center'}}>
                    <img src={GoodDoc} alt='GoodDoc Dashboard' style={{width:'35vw', height:'auto', objectFit: 'contain'}}></img>
                    
                </Grid> 
            </Grid>
            <Grid container alignItems={'center'} sx={{padding:'5vh 0', }}>
                <Grid item xs={12} md={6} lg={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={FDACitation483} alt='Root Cause Analysis' style={{width:'20vw', height:'auto', objectFit: 'contain'}} />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Typography variant='body1'>Avoid FDA Citation 483 + Warning Letters</Typography>
                </Grid>
            </Grid>
        </div>
    )
}
export default ProductsMain;