import React from 'react'
import NavBar from '../Components/NavBar';
import ProductsMain from '../Components/ProductsMain';
import FooterComponent from '../Components/FooterComponent';
import '../Css/Products.css'

const Products = () => {
    return(
        <div className='products'>
            <NavBar></NavBar>
            <ProductsMain></ProductsMain>
            <FooterComponent></FooterComponent>
        </div>
    )
}
export default Products;