import React from 'react'
import { Typography, Button,Grid, TextField } from "@mui/material";
import Theme from "../Theme";
import whiteLogo from '../images/Laver-Logo-White.png';
import cGMP from '../images/cGMPCompliance.png';
import GDP from '../images/GDPCompliance.png';
import HandwrittenErrorCheck from '../images/HandwrittenErrorCheck.png';
import RCA from '../images/RCA.png';
import FDA from '../images/FDA.png'
import Carousel from './Carousel'
import FDALogo from '../images/FDALogo.png';


const imageSources = [
    cGMP, 
    GDP,
    HandwrittenErrorCheck,
    RCA
]

const Hero = () => {
    return(
        <div className='hero' style={{border: '0px solid green'}}>
            <Grid container sx={{border: '0px solid blue'}} spacing={0}> 
                <Grid item xs ={4} sx={{marginLeft:'12.5vw', display:'inline-block', border: '0px solid red'}}>
                    <Typography variant='h2' sx={{marginTop: '5vh', width:'30vw', height:'auto'}}>
                        Helping You Follow The
                    </Typography>
                    <Typography variant='h2' sx={{marginBottom:'3.5rem', width:'30vw', height:'auto'}}>
                        Rules With AI 
                    </Typography>
                    <Typography variant='body1'>
                        Building AI-Powered Solutions 
                    </Typography>
                    <Typography variant='body1' sx={{marginBottom:'3.5rem'}}>
                        for Compliance. 
                    </Typography>
                    
                    <Grid container alignItems={'flex-start'} spacing={0}>
                        <Grid item xs={12} sm={6} sx={{ paddingRight: { xs: '0', sm: '2vw', border: '0px solid white' } }}>
                            <TextField
                            defaultValue=""
                            placeholder="Email"
                            size="small"
                            variant="outlined"
                            InputProps={{ notched: true }}
                            sx={{width:'16.3vw', borderRadius: '.5rem'}}
                           />
                        </Grid>
                        <Grid item xs={12} sm={6} container alignItems={'center'} justifyContent={'center'} sx={{border: '0px solid white'}}>
                            <Button className='gradientButton' sx={{color:'white', height: '3.5rem', width:'11rem', fontSize: '1rem', fontWeight: 600, borderRadius: '2rem'}}>
                                Join Wait List
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={5} container justifyContent={'center'} sx={{display:'flex', alignItems: 'center', height:'70vh', border: '0px solid green'}}>
                    <img src={FDA} alt="Error" style={{ objectFit: 'contain', maxHeight: '90%', width: 'auto', maxWidth: '100%' }}/>
                </Grid>
            </Grid>

            <Grid item xs={12} sx={{'border': '0px solid yellow', paddingLeft: '12.5vw', paddingRight: '12.5vw'}}>
                <Carousel imageSources={imageSources}></Carousel>
            </Grid>

            <Grid container className='divider' alignItems={'center'} sx={{paddingTop:'5vh'}}>
                <Grid item xs={12} sx={{}}>
                    <Typography variant='h3' sx={{textAlign:'center'}}>Compliant With GDOcP and cGMP</Typography>
                </Grid> 
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={FDALogo} alt='FDA Logo' style={{width:'12.5rem', height:'auto', objectFit: 'contain' }} />
                </Grid>
            </Grid>
        </div>
    )
}
export default Hero;