import { createTheme } from '@mui/material/styles';

const Theme = createTheme({
    palette: {
      primary: {
        main: '#353839',
        contrastText: '#FFFFFF'
      },
      secondary: {
        main: '#6BB6DF',
      },
    },
    typography: {
        fontFamily: 'Inter, Helvetica',
        h2: {
            fontWeight: 700,
            fontSize: '3.25rem',
            color: '#FFFFFF',
        },
        h3: {
            fontWeight: 700,
            fontSize: '2rem',
            color: '#FFFFFF',
        },
        h5: {
            fontWeight: 400,
            fontSize: '1.25rem',
            color: '#6BB6DF'
        },
        body1: {
            fontWeight:400,
            fontSize: '1.75rem',
            color: '#FFFFFF'
        },
        body2: {
            fontWeight:400,
            fontSize: '1.25rem',
            color: '#FFFFFF'
        },
        navbar: {
            fontWeight:600,
            fontSize: '1.1rem',
            color: '#FFFFFF'
        }
    },
    components: {
        MuiTextField: {
            styleOverrides: {
              root: {
                backgroundColor: 'white', // Set the background color to white
              },
            },
        },
        MuiButton:{
            styleOverrides:{
                root:{
                    fontWeight:600,
                    
                }
            }
        }
    }
  });
  
  export default Theme;