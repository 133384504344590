import React from 'react'
import NavBar from '../Components/NavBar';
import ProductsMain from '../Components/ProductsMain';
import AboutMain from '../Components/AboutMain';
import FooterComponent from '../Components/FooterComponent';
import '../Css/Home.css';
import Hero from '../Components/Hero';

const Home = () => {
    return(
        <div className='home'>
            <NavBar></NavBar>
            <Hero></Hero>
            <ProductsMain></ProductsMain>
            <AboutMain></AboutMain>
            <FooterComponent></FooterComponent>
        </div>
    )
}
export default Home;